import { defineStore } from 'pinia'

interface PermissionSettings {
  permission_setting_boolean_value: boolean | null
  permission_setting_id: number
  permission_setting_number_value: number | null
  permission_setting_string_value: string | null
  permission_setting_type_id: number
  permission_setting_type_name: string
  permission_setting_type_value_type: string
}

interface Permissions {
  id: string
  name: string
  show_name: string
  permission_settings: PermissionSettings[]
}

interface Plans {
  created_at: string
  guard_name: string
  id: number
  is_assignable: number
  module_id: number
  name: string
  service_id: number
  show_name: string
  updated_at: string
}

interface SelfPermissions {
  [key: string]: boolean | number | null
}

export const usePermissionStore = defineStore('GOODLINKER_PERMISSION', {
  state: () => ({
    permissions: [] as Permissions[],
    plans: [] as Plans[],
    self: {} as SelfPermissions
  }),
  actions: {
    setSelfPermissions (userType: string) {
      const convertToCamelCase = (str: string) => {
        return str
          .toLowerCase()
          .split(' ')
          .map((word, index) =>
            // 將每個單詞的首字母轉換為大寫，除了第一個單詞前加上 "can"
            index === 0 ? `can${word.charAt(0).toUpperCase() + word.slice(1)}` : word.charAt(0).toUpperCase() + word.slice(1))
          .join('')
      }
      const combineArray = [ ...this.permissions, ...this.plans ]
      const permissionsObject = combineArray.reduce<SelfPermissions>((acc, item) => {
        const name = convertToCamelCase(item.name)
        if (!Object.prototype.hasOwnProperty.call(acc, name)) {
          acc[name] = false
        } else {
          acc[name] = true
        }
        if ('permission_settings' in item && item.permission_settings.length > 0) {
          item.permission_settings.forEach(setting => {
            switch (setting.permission_setting_type_name) {
              case '自訂首頁頁面數量上限':
                acc.canCustomPageAmount = setting.permission_setting_number_value
                break
              case '分析圖表數量限制':
                acc.canAnalysisChartAmount = setting.permission_setting_number_value
                break
              default:
                break
            }
          })
        }
        return acc
      }, {})
      this.self = permissionsObject

      if (userType === 'official' || userType === 'observer') {
        // 取得所有權限，全部設為 true
        this.self = {
          canReadAlarmAnalysisPage: true,
          canReadDashboard: true,
          canManageDataCenter: true,
          canReadHistoryChart: true,
          canReadHistoryChartInDay: true,
          canReadHistoryChartByDay: true,
          canReadHistoryChartByYear: true,
          canReadAnalysisChart: true,
          canManageAnalysisChart: true,
          canDownloadHistoryExcelInDay: true,
          canDownloadHistoryExcelByDay: true,
          canReadCustomPage: true,
          canManageCustomPage: true,
          canManageUser: userType === 'official',
          canManageTag: true,
          canManageTagGroups: true,
          canReadUser: true,
          canReadDeviceInfo: true,
          canCustomPageAmount: 99,
          canAnalysisChartAmount: 99
        }
      }
    }
  }
})
