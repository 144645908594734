import request from '@/plugins/Axios'

export function apiGetCompanies () {
  return request({
    url: '/common/v3/companies',
    method: 'get'
  })
}

export function apiGetCompany (companyId: number) {
  return request({
    url: `/common/v3/companies/${companyId}`,
    method: 'get'
  })
}

export function apiGetLesiesStatus (companyId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/lesies/status`,
    method: 'get'
  })
}

// API: 取得單一公司的使用者資料
export function apiGetUsersMe (companyId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/users/me`,
    method: 'get'
  })
}

// API: 取得單一公司的所有標籤群組
export function apiGetCompanyAllPositions (companyId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/position/all-position`,
    method: 'get'
  })
}

// API: 取得單一公司單一標籤群組裡的所有標籤
export function apiGetCompanyPositionTags (companyId: number, positionId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/position/position-tag/${positionId}`,
    method: 'get'
  })
}

// API: 取得單一公司指定標籤的 Sensor
export function apiGetTagSensors (tagId: string) {
  return request({
    url: `/common/v3/tags/tag-sensors/${tagId}`,
    method: 'get'
  })
}

// API: 取得單一公司預設標籤群組裡的所有標籤
export function apiGetCompanyPositionDefaultTags (companyId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/position/default-position-tag`,
    method: 'get'
  })
}

// API: 新增單一公司的單一標籤群組
export function apiPostCompanyPosition (companyId: number, data: { positionName: string, tags: number[] }) {
  return request({
    url: `/common/v3/companies/${companyId}/position/create-company-position`,
    method: 'post',
    data
  })
}

// API: 刪除單一公司的單一標籤群組
export function apiDelCompanyPositionTags (companyId: number, positionId: number) {
  return request({
    url: `/common/v3/companies/${companyId}/position/delete-position-tag/${positionId}`,
    method: 'delete'
  })
}

// API: 更新單一標籤群組裡的所有標籤
export function apiPostCompanyPositionTags (companyId: number, positionId: number, data: { positionName: string, tags: number[] }) {
  return request({
    url: `/common/v3/companies/${companyId}/position/update-position-tag/${positionId}`,
    method: 'post',
    data
  })
}

// API: 為使用者指定標籤群組
export function apiPatchAssignUserPosition (companyId: number, positionId: number, data: { userId: number }) {
  return request({
    url: `/common/v3/companies/${companyId}/position/assign-user-position/${positionId}`,
    method: 'patch',
    data
  })
}

// API: 取得事件簿中，時間範圍內的事件卡清單及內容
export function apiGetEventbook (thingName: string, from: number, to: number, cardName: string) {
  return request({
    url: `/common/v3/thing/${thingName}/eventbook/from/${from}/to/${to}/${cardName}`,
    method: 'get'
  })
}

// API: 取得該公司所有事件簿規則
export function apiGetEventbookRules (type: string, companyId: number) {
  return request({
    url: `/common/v3/eventbooks/rules/${type}/companies/${companyId}`,
    method: 'get'
  })
}

// API: 從萊西取得事件簿
export function apiGetEventbookFromLesi (type: string, thingName: string) {
  return request({
    url: `/common/v3/eventbooks/rules/${type}/thing/${thingName}`,
    method: 'get'
  })
}

// API: 從萊西、事件卡名取得事件簿
export function apiGetEventbookFromLesiCard (type: string, thingName: string, cardName: string) {
  return request({
    url: `/common/v3/eventbooks/rules/${type}/thing/${thingName}/cardname/${cardName}`,
    method: 'get'
  })
}

// API: 更新事件簿規則
export function apiPatchEventbookFromLesiCard (type: string, thingName: string, cardName: string, data: any) {
  return request({
    url: `/common/v3/eventbooks/rules/${type}/thing/${thingName}/cardname/${cardName}`,
    method: 'patch',
    data
  })
}
