import axios from 'axios'
import { ElMessageBox } from 'element-plus'
import { useUserStore, useEventHandlerStore } from '@/stores'
import { i18n } from '@/plugins/i18n'
import 'element-plus/theme-chalk/el-message-box.css'

const { t } = i18n.global

const api = axios

const offlineToken = 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsImp0aSI6Ijc3NzM0Mzk1OWY2MzdiZjljMjhjNDY3YmFkMGIyM2YwOTZiOWZiZDdkODdjNTZhZGJlMDYzOTJkNmU2MmRiNjU3NDE2MzRmNjI3ZDE2YmE5In0.eyJhdWQiOiIxIiwianRpIjoiNzc3MzQzOTU5ZjYzN2JmOWMyOGM0NjdiYWQwYjIzZjA5NmI5ZmJkN2Q4N2M1NmFkYmUwNjM5MmQ2ZTYyZGI2NTc0MTYzNGY2MjdkMTZiYTkiLCJpYXQiOjE1NjU4NDg2NjIsIm5iZiI6MTU2NTg0ODY2MiwiZXhwIjoxNTk3NDcxMDYyLCJzdWIiOiIzIiwic2NvcGVzIjpbXX0.IjWVgsX63BhrtX66PQUWOFmvVZ4KYXf5xSsOuLCC0-QZjkAteUkbcGJqhayZJZ4iOdv5cKCQH98KmL9ICFBzbDw3K3Pk9sMutQrUKRIaaP2FgxcSVahf8acEg3ozt9VtAay930bDHMq99C-uAhkN-mqIApT69Ias7Eaq1Tme67P4NdwS_4MQwCDmNDQmLDsmP9n-zdDNXvnGWHzCN8N8ZXq3UYNmJgDgPLkDfS-rlZRSogrBysEYNQ1cMrhzRxu7GtWpooU_B_rNAQGfrvM-rWO8JjFBGqRU3FSIAv3yY0XBOH24BBfA_feIjyjkQ9FlKFJuR_TuqP5I-shc9rTKhk3yTjayPeWX-XepDMwzzrkEzQIVq2WLkfcOI_TpmzViiYV3DJsbF95EFJaYfg5Y2KmkFoR_D2_4ZiovycfMjbF7O2R3XD0jgneBNUOb6IrnuCaFx0Y22UzQIDYGg5p2AXxTfZHk17Tr9QDpjFOthYKkrpZsGkHGlPYOpJXCmGZhnLjp4N9OqTen80OlVOPTeDmT52RySOu6r36VlAkHPIMfPeJV1ZjdzLsZ22i-HcITa7koKPj1G8i0z-_Wj3hOdQrnDJL7FhZa-NU-j_fx5Y85Wv2N6MXg8xsCDYZwYDcjAEiuJRf4YCZl31BCPgYexkIWCJyPv5GkWymPo801Hfw'

const refreshToken = () => {
  const userStore = useUserStore()
  const rToken = userStore.auth.RefreshToken
  const sub = userStore.auth.Sub

  const headers = {
    Authorization: offlineToken,
    Source: 'warroom-web',
    'Content-Type': 'application/json'
  }
  const payload = {
    rToken,
    sub
  }
  return axios.post('auth/v3/ciam/getNewToken',
    payload,
    { headers })
}

const tokenExpired = () => {
  const userStore = useUserStore()
  ElMessageBox.alert(t('messages.tokenExpired.message'), t('messages.tokenExpired.title'), {
    confirmButtonText: t('actions.btn--confirm'),
    callback: () => {
      userStore.$reset()
      window.location.href = '/login'
    }
  })
}

api.interceptors.request.use((config) => {
  if (config.tunnel === 'warroom') {
    config.baseURL = import.meta.env.VITE_API_WARROOM_URL
  } else {
    config.baseURL = import.meta.env.VITE_APP_URL
  }
  const isOffline = config.url?.includes('/ciam/') && !config.url?.includes('/ciam/online/')
  const userStore = useUserStore()
  const headers = config.headers || {}

  if (isOffline) {
    headers.Authorization = offlineToken
  } else if (userStore.auth.IdToken) {
    headers.Authorization = `Bearer ${userStore.auth.IdToken}`
    headers.Source = 'warroom-web'
    headers['Content-Type'] = 'application/json'
  }

  if (config.url?.includes('/excelreport/S3')) {
    headers['Content-Type'] = 'multipart/form-data'
  }
  config.headers = headers

  return config
},
error => {
  console.error('Request error:', error)
  return Promise.reject(error)
})

api.interceptors.response.use(response => {
  return response
},
async error => {
  if (error.response) {
    const userStore = useUserStore()
    const eventHandlerStore = useEventHandlerStore()
    const errorCode = error.response.data.code || null
    let content = ''
    if (error.response.status === 401 && error.response.data.message === 'The incoming token has expired') {
      try {
        const res = await refreshToken()
        userStore.$state.auth.IdToken = res.data.data.newIdToken
        userStore.$state.auth.AccessToken = res.data.data.newAccessToken
        return api(error.config)
      } catch (err: any) {
        console.error('token 過期', err)
        if (err.response.data.code === '0182') {
          const logData = {
            path: error.config.url,
            action: 'tokenExpired',
            info: JSON.stringify(err.response.data)
          }
          eventHandlerStore.setLogData(logData)
          userStore.$state.auth.Expired = true
          tokenExpired()
          return
        }
      }
    } else if (error.response.status === 502) {
      content = `<p>Error Code: 502 Bad Gateway</p>${t('errorCode.default')}`
      ElMessageBox.alert(content, t('errorCode.title'), {
        dangerouslyUseHTMLString: true,
        confirmButtonText: t('actions.btn--confirm')
      })
      const logData = {
        path: error.config.url,
        action: 'Error Code: 502 Bad Gateway',
        info: JSON.stringify(error.response.data)
      }
      eventHandlerStore.setLogData(logData)
    }
    switch (errorCode) {
      case '0160':
      case '0162':
      case '0302':
      case '0303':
      case '0306':
      case '0362':
      case '0462':
      case '0502':
      case '0512':
      case '0513':
      case '0532':
      case '0533':
      case '0592':
      case '0642':
      case '0643':
      case '0652':
      case '0662':
      case '0702':
      case '0712':
      case '0722':
      case '0732':
      case '0752':
      case '1081':
      case '1091': {
        content = `<p>Error Code ${errorCode}</p>${t(`errorCode.${errorCode}`)}`
        ElMessageBox.alert(content, t('errorCode.title'), {
          dangerouslyUseHTMLString: true,
          confirmButtonText: t('actions.btn--confirm')
        })
        const logData = {
          path: error.config.url,
          action: error.message || error.name || error.code,
          info: JSON.stringify(error.response.data)
        }
        eventHandlerStore.setLogData(logData)
        break
      }
      default: {
        content = `<p>Error Code ${errorCode}</p>${t('errorCode.default')}`
        ElMessageBox.alert(content, t('errorCode.title'), {
          dangerouslyUseHTMLString: true,
          confirmButtonText: t('actions.btn--confirm')
        })
        const logData = {
          path: error.config.url,
          action: error.message || error.name || error.code,
          info: JSON.stringify(error.response.data)
        }
        eventHandlerStore.setLogData(logData)
        break
      }
    }
  }
  return Promise.reject(error)
})

export default api
