import request from '@/plugins/Axios'
// import requestWarroom from '@/plugins/AxiosWarroom'

// API: 取得單一公司的所有使用者
export function apiGetCompanyUsers (companyId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/users`,
    method: 'get'
  })
}

export function apiPostCompanyUsersAdd (companyId: number, data: { email: string }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/users/add`,
    method: 'post',
    data
  })
}

export function apiPostCompanyUsersRemove (companyId: number, data: { userId: number }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/users/remove`,
    method: 'post',
    data
  })
}

export function apiGetCompanyRoles (companyId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/roles`,
    method: 'get'
  })
}

export function apiPatchCompanyUserRoles (companyId: number, userId: number, data: { roleId: number }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/users/${userId}/roles`,
    method: 'patch',
    data
  })
}

// API: 取得單一公司的所有感測器
export function apiGetCompanySensors (companyId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/sensors`,
    method: 'get'
  })
}

// API: 取得單一公司的所有標籤
export function apiGetCompanyTags (companyId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/tags`,
    method: 'get'
  })
}

// API: 取得單一公司單一標籤的所有 sensor 列表
export function apiGetCompanyTagSensors (companyId: number, tagId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/tags/${tagId}/sensors`,
    method: 'get'
  })
}

// API：刪除單一公司的單一 sensor
export function apiDelCompanySensors (companyId: number, sensorId: string | number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/sensors/${sensorId}`,
    method: 'delete'
  })
}

// API: add tags to sensors
export function apiPostCompanySensorsTags (companyId: number, data: { sensors: number[], tags: number[] }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/sensors/tags`,
    method: 'post',
    data
  })
}

// API: 新增標籤
export function apiPostCompanyTags (companyId: number, data: { tagName: string }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/tags`,
    method: 'post',
    data
  })
}

// API: 取得單一公司所有感測器的群組
export function apiPostSensorsGroups (companyId: number, data: { things: string[] }) {
  return request({
    url: `/warroom/v3/companies/${companyId}/sensors/groups`,
    method: 'post',
    data
  })
}

// API: 解綁標籤與感測器的關聯關係
export function apiDelTagsSensors (companyId: number, tagId: number, sensorId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/tags/${tagId}/sensors/${sensorId}`,
    method: 'delete'
  })
}

export function apiDelTag (companyId: number, tagId: number) {
  return request({
    url: `/warroom/v3/companies/${companyId}/tags/${tagId}`,
    method: 'delete'
  })
}
