import { defineStore } from 'pinia'
import { useUserStore } from '@/stores'
import * as Sentry from '@sentry/vue'
// import { apiPostFrontLogStore } from '@/models/api/log'
// import { convertDateToDateAndTime } from '@/plugins/TimeConvert'

type IntervalId = ReturnType<typeof setInterval> | null

interface LogState {
  data: {
    userId: number | null
    email: string | null
    env: string
    path: string
    action: string
    info: string
  }[]
  intervalId: IntervalId,
}

interface LogData {
  path: string
  action: string
  info: string
}

export const useEventHandlerStore = defineStore('eventHandler', {
  state: (): LogState => ({
    data: [],
    intervalId: null
  }),
  persist: {
    enabled: true,
    strategies: [{ storage: sessionStorage, paths: ['data'] }]
  },
  actions: {
    // startTimer () {
    //   if (this.intervalId) return
    //   this.intervalId = setInterval(() => {
    //     this.storeLogData()
    //   }, 1 * 60 * 1000)
    // },
    // clearTimer () {
    //   if (this.intervalId) {
    //     clearInterval(this.intervalId)
    //     this.intervalId = null
    //   }
    // },
    async setLogData (logData: LogData) {
      const { path, action, info } = logData
      const userStore = useUserStore()
      const userId = userStore.user.id || null
      const email = userStore.user.email || ''
      const env = import.meta.env.MODE
      // const eventId = Sentry.lastEventId()
      const locale = localStorage.getItem('language') || 'zh-TW'
      this.data.push({
        userId,
        email,
        env,
        path,
        action,
        info
      })
      // NOTE: User Feedback 可以給使用者回報使用，目前先關閉
      // const userFeedback = {
      //   name,
      //   email,
      //   message: `[${logData.path}] ${logData.action}`,
      //   associatedEventId: eventId
      // }
      // Sentry.captureFeedback(userFeedback)
      Sentry.setTag('page_locale', locale)
      Sentry.addBreadcrumb({
        message: `[${logData.path}] ${logData.action}`,
        category: 'log',
        level: 'info',
        data: {
          action: logData.action,
          info: logData.info
        }
      })
      // if (this.data.length > 0 && !this.intervalId) {
      //   this.startTimer()
      // }
    }
    // async storeLogData () {
    //   try {
    //     if (this.data.length > 0) {
    //       await apiPostFrontLogStore(this.data)
    //     }
    //   } catch (error) {
    //     console.error('storeLog error', error)
    //   } finally {
    //     this.data.length = 0
    //   }
    // },
    // onUnmounted () {
    //   this.clearTimer()
    // }
  }
})
