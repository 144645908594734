import { i18n } from '@/plugins/i18n'
import { useEventHandlerStore } from '@/stores'
import { ElMessageBox } from 'element-plus'
import 'element-plus/theme-chalk/el-message-box.css'

const { t } = i18n.global

const clearUserData = (): void => {
  localStorage.removeItem('USER')
}

export const versionControlService = {
  VITE_APP_VERSION: import.meta.env.VITE_APP_VERSION,

  handleVersionLog: {
    clientVersion: localStorage.getItem('VERSION') || null,
    error () {
      const eventHandlerStore = useEventHandlerStore()
      const logData = {
        path: 'versionControlService.ts',
        action: '登入版本不符',
        info: `使用者的版本為 ${this.clientVersion}，當前版本為 ${versionControlService.VITE_APP_VERSION}`
      }
      eventHandlerStore.setLogData(logData)
    }
  },

  setAppVersion (): void {
    localStorage.setItem('VERSION', this.VITE_APP_VERSION)
  },

  checkVersion () {
    const clientVersion = localStorage.getItem('VERSION') || null
    if (clientVersion === null || clientVersion !== this.VITE_APP_VERSION) {
      return false
    }
    return true
  },

  handleVersionMismatch (routeName?: string) {
    if (routeName === 'Login') {
      clearUserData()
    } else {
      this.handleVersionLog.error()
      ElMessageBox.alert(t('messages.version.message'), t('messages.version.title'), {
        confirmButtonText: t('actions.btn--confirm'),
        callback: () => {
          this.setAppVersion()
          window.location.reload()
        }
      })
    }
  }
}
